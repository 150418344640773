import React from 'react';
import { SubTypeValue } from '@/src/utils/types';
import NoResultTip from './noResultTip';
import GroupTitle from './groupTitle';

const FilterNoResultRecommend = ({ ...props }) => {
	const { reason } = props;

	return (
		<div className="new-demand">
			<NoResultTip
				iconClassName="ico-noresult"
				label={reason.text}
				subBlockType={SubTypeValue.NoFilteredResult}
			/>
			<GroupTitle iconClassName={reason.iconClass || 'ico-subairport'} title={reason.title} tip={reason.hint} />
		</div>
	);
};

export default FilterNoResultRecommend;
