import React from 'react'
import { connect } from 'react-redux'
import MultiCheapest from '../../../../components/list/result/recommend/multiCheapest'
import { prevCondSelector, comfortTagsSelector, lastSearchTimeSelector, globalSwitchSelector, containsTaxSelector, getSearchCriteriaTokenSelector } from '../flight/baseSelector'
import { UbtBoundary, ListUbtGenerator, LIST_UBT_GROUP_TYPES, LIST_UBT_KEY_TYPES, UbtHandler } from '../../../../ubt.v2/list'
import converter, { SessionHandler } from '../../../../sources/list/converter';

class MultiCheapestContainer extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            penaltyTag: null,
            comfortPopupIndex: 0
        }
        this.changeComfortPopupIndex = this.changeComfortPopupIndex.bind(this)
    }

    changeComfortPopupIndex(index) {
      if (index === this.state.comfortPopupIndex) return;
      this.setState({ comfortPopupIndex: index })
    }

    componentDidMount() {
        UbtHandler.onUploadData('c_mult_cheapest_recommend_show', {})
    }

    render() {
      let { multiCheapest, includeTax, prevCond, comfortTags, lastSearchTime, globalSwitch, onPopupFlightDetail,
            ubtOriginFlight, searchCriteriaToken } = this.props
        let ubtSequence = [],
            ubtOtherParams = {
                Price: multiCheapest.getIn([0, includeTax ? 'minPriceWithTax' : 'minPriceWithoutTax', 'avg']),
                IsTax: includeTax ? 'T' : 'F'
            }

        multiCheapest.getIn([0, 'flightSegments']).forEach(flight => {
            ubtSequence.push({
                From: flight.get('departureCityCode'),
                To: flight.get('arrivalCityCode'),
                Duration: flight.get('duration'),
                StartTime: flight.get('departureDateTime')
            })
        })

        let onBook = (...args) => {
            let ubtSourceInfoOfThis = {
                Source: 1,
                Type: 8,
                Position: 'Top',
                RecommendOrder: 1,
                Sequence: [],
                OriginFlight: ubtOriginFlight
            },
                ubtProps = {},
                ubtSourceInfoOfListPage = LIST_UBT_GROUP_TYPES.BASIC_DATA_FLOW.value.rawData.RecommendProduct ? LIST_UBT_GROUP_TYPES.BASIC_DATA_FLOW.value.rawData.RecommendProduct.SourceInfo : null
            ubtSourceInfoOfThis = Object.assign(ubtSourceInfoOfThis, ubtOtherParams)
            prevCond.get("flightSegments").forEach(searchedFlightSegment => {
                ubtSourceInfoOfThis.Sequence.push({
                    From: searchedFlightSegment.get("departureCityCode"),
                    To: searchedFlightSegment.get("arrivalCityCode"),
                    StartTime: searchedFlightSegment.get("departureDate")
                })
            })
            ubtProps.SourceInfo = converter.ubtSourceInfoCombiner(ubtSourceInfoOfListPage, ubtSourceInfoOfThis)
            LIST_UBT_GROUP_TYPES.BASIC_DATA_FLOW.value.rawData.RecommendProduct = {
                IsShow: "",
                RecommendInfo: []
            }
            converter.saveRecommendSourceInfoForBookingPage(ubtProps, prevCond.get("transactionID"))
            SessionHandler.setAdjacentRecommendType(ubtSourceInfoOfThis)

            UbtHandler.onUploadData('c_mult_cheapest_recommend_click', {})

            this.props.onBook(...args)
        }

        let penaltyTag = multiCheapest.getIn([0, "priceList", 0, 'penalty', 'defaultPenaltyTag'])

        return (
            <UbtBoundary tracker={ListUbtGenerator(LIST_UBT_GROUP_TYPES.BASIC_DATA_FLOW, LIST_UBT_KEY_TYPES.RECOMMEND_FLIGHT_LOAD, 8, 1, "TopInfo",
                ubtSequence, ubtOtherParams)}>
                <MultiCheapest globalSwitch={globalSwitch} flights={multiCheapest.get(0)} includeTax={includeTax} penaltyTag={penaltyTag}
                    prevCond={prevCond} comfortTags={comfortTags} onBook={onBook} lastSearchTime={lastSearchTime}
                    changeComfortPopupIndex={this.changeComfortPopupIndex} comfortPopupIndex={this.state.comfortPopupIndex}
                    onPopupFlightDetail={onPopupFlightDetail} searchCriteriaToken={searchCriteriaToken}    />
            </UbtBoundary>)
    }
}

const mapStateToProps = (state) => ({
    includeTax: containsTaxSelector(state),
    prevCond: prevCondSelector(state),
    comfortTags: comfortTagsSelector(state),
    lastSearchTime: lastSearchTimeSelector(state),
    globalSwitch: globalSwitchSelector(state),
    searchCriteriaToken: getSearchCriteriaTokenSelector(state)
})

const mapDispatchToProps = () => ({

})

export default connect(mapStateToProps, mapDispatchToProps)(MultiCheapestContainer)
