import { createSelector } from 'reselect'
import {
    flightsSelector, currentSegmentSeqSelector, anyFlightIsDirectSelector, prevCondSelector,
    searchFlightsIsFinishedSelector, isBuildUpSelector
} from '../flight/baseSelector'
import { searchNoResultRecommendSelector } from './searchNoResultRecommendSelector'
import Converter from '../../../../sources/list/converter'
import { filterVisibleFlightsSelector } from '../../sidebar/filterbar/filterbarSelector';

export const showFilterNoResultSelector = (state) => state.getIn(['list', 'recommend', 'showFilterNoResult'])

export const getFilterNoResultReasonSelector = createSelector([searchFlightsIsFinishedSelector, flightsSelector, currentSegmentSeqSelector, prevCondSelector,
    anyFlightIsDirectSelector, searchNoResultRecommendSelector, isBuildUpSelector, filterVisibleFlightsSelector],
    (isFinished, flights, currentSegment, prevCond, anyFlightIsDirect, searchNoResultRecommend, isBuildUp, visibleFlights) => {
        if (isFinished && !searchNoResultRecommend) {
            //获得查询条件
            let departureAirportCode = isBuildUp ? prevCond.getIn(['flightSegments', 0, 'departureAirportCode']) : prevCond.getIn(['flightSegments', currentSegment, 'departureAirportCode']),
                arrivalAirportCode = isBuildUp ? prevCond.getIn(['flightSegments', 0, 'arrivalAirportCode']) : prevCond.getIn(['flightSegments', currentSegment, 'arrivalAirportCode']),
                airlineCode = prevCond.getIn(['airline', 'code']) || '',
                airlineName = prevCond.getIn(['airline', 'name']) || '',
                directFlight = prevCond.get('directFlight'),
                reason = null

            let anyFlightNoHasAirlineCode = false,
                anyFlightNoHasDepartureAirportCode = false,
                anyFlightNoHasArrivalAirportCode = false,
                direct = !!directFlight && !anyFlightIsDirect,
                getAllAirportCodes = (segmentSeq) => {
                    const secondSegmentAirports = Converter.getAllAirportOfFlights(flights.get('list'), segmentSeq),
                        secondSegmentDepartAirportCodes = Object.keys(secondSegmentAirports.departure),
                        secondSegmentArrivalAirportCodes = Object.keys(secondSegmentAirports.arrival)

                    return {
                        departure: secondSegmentDepartAirportCodes,
                        arrival: secondSegmentArrivalAirportCodes
                    }
                }
            if (isBuildUp) {
                // 获得搜索结果航班中所有的航司和机场
                let airlines = Converter.getAllAirlineOfFlights(flights.get('list'), [0, 1]),
                    { departure: firstSegmentDepartAirportCodes, arrival: firstSegmentArrivalAirportCodes } = getAllAirportCodes(0),
                    { departure: secondSegmentDepartAirportCodes, arrival: secondSegmentArrivalAirportCodes } = getAllAirportCodes(1)

                // 搜索了该条件但是结果不包含
                anyFlightNoHasAirlineCode = !!airlineCode && !Object.keys(airlines.airline).includes(airlineCode)
                anyFlightNoHasDepartureAirportCode = !!departureAirportCode && !firstSegmentDepartAirportCodes.includes(departureAirportCode) && !secondSegmentArrivalAirportCodes.includes(departureAirportCode)
                anyFlightNoHasArrivalAirportCode = !!arrivalAirportCode && !firstSegmentArrivalAirportCodes.includes(arrivalAirportCode) && !secondSegmentDepartAirportCodes.includes(arrivalAirportCode)
            }
            else {
                // 获得搜索结果航班中所有的航司和机场
                const { departure: departAirportCodes, arrival: arrivalAirportCodes } = getAllAirportCodes(currentSegment)
                let airlines = Converter.getAllAirlineOfFlights(flights.get('list'), currentSegment)

                // 搜索了该条件但是结果不包含
                anyFlightNoHasAirlineCode = !!airlineCode && !Object.keys(airlines.airline).includes(airlineCode)
                anyFlightNoHasDepartureAirportCode = !!departureAirportCode && !departAirportCodes.includes(departureAirportCode)
                anyFlightNoHasArrivalAirportCode = !!arrivalAirportCode && !arrivalAirportCodes.includes(arrivalAirportCode)
            }

            let airport = anyFlightNoHasDepartureAirportCode || anyFlightNoHasArrivalAirportCode

            if (airport && !anyFlightNoHasAirlineCode && !direct) {
                reason = {
                    type: 'subAirport',
                    ubtType: ['子机场'],
                    departureAirport: isBuildUp ? true : anyFlightNoHasDepartureAirportCode,
                    arrivalAirportCode: isBuildUp ? true : anyFlightNoHasArrivalAirportCode
                }
            }
            else if (anyFlightNoHasAirlineCode && !airport && !direct) {
                reason = {
                    type: 'airLine',
                    ubtType: ['限航司'],
                    airlineName: airlineName
                }
            }
            else if (direct && !airport && !anyFlightNoHasAirlineCode) {
                reason = {
                    type: 'isDirect',
                    ubtType: ['仅看直飞']
                }
            }
            else if (airport || anyFlightNoHasAirlineCode || direct) {
                let ubtTypes = []
                airport && ubtTypes.push('子机场')
                anyFlightNoHasAirlineCode && ubtTypes.push('限航司')
                direct && ubtTypes.push('仅看直飞')
                reason = {
                    type: 'mutilCondtion',
                    ubtType: ubtTypes
                }
            }
            else if (visibleFlights.size == 0 && (departureAirportCode || arrivalAirportCode || directFlight || airlineCode)) {
                // 机场都存在，刚好没有这一条航线
                // 机场存在，直飞也在，交叉无结果
                // 多种交叉无结果的情况
                let ubtTypes = []
                if (departureAirportCode || arrivalAirportCode) {
                    ubtTypes.push('子机场')
                }
                airlineCode && ubtTypes.push('限航司')
                directFlight && ubtTypes.push('仅看直飞')
                reason = {
                    type: 'mutilCondtion',
                    ubtType: ubtTypes
                }
            }
            return reason
        }
        return null
    })

export const showFilterNoResultWrapperSelector = createSelector(
    [getFilterNoResultReasonSelector, searchFlightsIsFinishedSelector, showFilterNoResultSelector],
    (reason, isFinished, showFilterNoResult) => {
        if (showFilterNoResult !== null) {
            return showFilterNoResult
        } else if (!isFinished) {
            return null
        } else {
            return reason
        }
    }
)
