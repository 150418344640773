import React from "react"
import moment from 'moment';
import { UbtBoundary, ListUbtGenerator, LIST_UBT_GROUP_TYPES, LIST_UBT_KEY_TYPES } from '../../../../ubt.v2/list'
import converter, { SessionHandler } from '../../../../sources/list/converter';

const SubAirportRecommend = ({ subAirport, minPrice, prevCond, includeTax, showAllFlights, ubtOriginFlight }) => {

    let flightsCount = subAirport.get('flightsCount'),
        directFlightsCount = subAirport.get('directFlightsCount'),
        title = subAirport.get('title'),
        type = subAirport.get('type'),
        flightWay = prevCond.get('flightWay')

    const iconClassName = () => {
        switch (type) {
            case 1:
                return 'ico-depart-airport'
            case 2:
                return 'ico-arrive-airport'
            case 3:
                return 'ico-flt-airport'
        }
    }
    const showPrice = () => {
        return (
            <div className="recmd-price">
                <div className="price">
                    <dfn>¥</dfn>{includeTax ? minPrice.get('totalPrice') : minPrice.get('price')}<span className="qi">起</span>
                </div>
                {includeTax
                    ? <div className="hint">{flightWay === 'S' ? '' : '往返'}含税价</div>
                    : <div className="hint tax">{flightWay === 'S' ? '' : '往返'}税费<dfn>&yen;</dfn>{minPrice.get('totalPrice') - minPrice.get('price')}</div>}
            </div>
        )
    }
    let sequence = prevCond.get("flightSegments").map((flightSegment, index) => {
        return {
            Segno: index + 1,
            From: flightSegment.get("departureCityCode"),
            To: flightSegment.get("arrivalCityCode"),
            StartTime: moment(flightSegment.get("departureDate")).format("YYYY-MM-DD HH:mm:ss")
        }
    }),
        otherParams = {
            Price: includeTax ? minPrice.get('totalPrice') : minPrice.get('price'),
            IsTax: includeTax ? "T" : "F",
        }

    const showAllFlightsAddUbt = () => {
        //存储sourceInfo,用于填写页埋点
        let ubtSourceInfoOfThis = {
            Source: 1,
            Type: 9,
            Position: 'Top',
            RecommendOrder: 1,
            Sequence: sequence,
            OriginFlight:ubtOriginFlight
        },
            ubtProps = {},
            ubtSourceInfoOfListPage = LIST_UBT_GROUP_TYPES.BASIC_DATA_FLOW.value.rawData.RecommendProduct ? LIST_UBT_GROUP_TYPES.BASIC_DATA_FLOW.value.rawData.RecommendProduct.SourceInfo : null
        ubtSourceInfoOfThis = Object.assign(ubtSourceInfoOfThis, otherParams)
        prevCond.get("flightSegments").forEach(searchedFlightSegment => {
            ubtSourceInfoOfThis.Sequence.push({
                From: searchedFlightSegment.get("departureCityCode"),
                To: searchedFlightSegment.get("arrivalCityCode"),
                StartTime: searchedFlightSegment.get("departureDate")
            })
        })
        ubtProps.SourceInfo = converter.ubtSourceInfoCombiner(ubtSourceInfoOfListPage, ubtSourceInfoOfThis)
        converter.saveRecommendSourceInfoForBookingPage(ubtProps, prevCond.get("transactionID"))
        LIST_UBT_GROUP_TYPES.BASIC_DATA_FLOW.value.rawData.RecommendProduct = {
            IsShow: "",
            RecommendInfo: []
        }
        SessionHandler.setAdjacentRecommendType(ubtSourceInfoOfThis)
        showAllFlights()
    }

    return (
        <UbtBoundary tracker={ListUbtGenerator(LIST_UBT_GROUP_TYPES.BASIC_DATA_FLOW, LIST_UBT_KEY_TYPES.RECOMMEND_FLIGHT_LOAD, 9, 1, "TopInfo",
            sequence, otherParams)}>
            <div className="recommend-box header">
                <div className="sub-airport">
                    <div className="recmd-title">
                        <i className={iconClassName()}></i>
                        <span className="txt">{title}</span>
                        <span className="hint">多个机场，更多选择</span>
                        <div className="total">共{flightsCount}个航班，{directFlightsCount}个直飞</div>
                    </div>

                    {showPrice()}

                    <UbtBoundary tracker={ListUbtGenerator(LIST_UBT_GROUP_TYPES.SUB_AIRPORT, LIST_UBT_KEY_TYPES.SUB_AIRPORT, prevCond.get('flightWay'))}>
                        <div className="recmd-action"><div u_key='sub_airport_recommend' u_remark={`查看子机场推荐[${title}]`} className="btn btn-recmd" onClick={showAllFlightsAddUbt}>查看</div></div>
                    </UbtBoundary>
                </div>
            </div>
        </UbtBoundary>
    )
}

export default SubAirportRecommend
