import React from 'react'
import classnames from 'classnames'
import ReactToolTip from '@/src/components/Base/reactToolTip';
import FlightSummary from '../../flightSummary/flightSummary'
import PRICE_MESSAGE from '../../../../constants/list/enums/priceMessage'
import { FLIGHT_WAY } from '../../../../constants/list/enums';

const MultiCheapest = ({ flights, includeTax, penaltyTag, prevCond, comfortTags, onBook, changeComfortPopupIndex,
    comfortPopupIndex, globalSwitch, onPopupFlightDetail, searchCriteriaToken }) => {
    let price = flights.getIn(["priceList", 0]),
        avgPrice = includeTax ? price.get("avgPriceWithTax") : price.get("avgPriceWithoutTax"),
        ticketCount = price.get("ticketCount"),
        avgTax = price.get("avgTax"),
        flightWayEnum = prevCond.get('flightWayEnum').value,
        isMultiplePassengerType = prevCond.get('isMultiplePassengerType'),
        getGVLimitationsTooltip = (wrapper) => <ReactToolTip parentId="tagLimit" key="tagLimit" toolTipContent={wrapper.restrict.value.getTooltip(wrapper)} />,
        getMultiGVLimitationsTooltipContent = (wrapper) => <div className='tooltip'><div className='tooltip-content'><h3>{PRICE_MESSAGE.GV_TAG_HOVER.Title}</h3>{wrapper.map((restrict, index) => <p key={index}>{index + 1}、{PRICE_MESSAGE.GV_TAG_HOVER.Multi.replace(/\[VALUE\]/g, restrict.label)}</p>)}</div></div>,
        restrictionList = price.get('restrictionList'),
        isSuperFlyer = flights.get('itineraryTags').some(tag => tag.get('type') == 'SuperFlyer'),
        GVLimitationsEnums = price.get('GVLimitationsEnums'),
        passengerRestrictionTag = restrictionList.size ? (
            <React.Fragment>
                <div className="limit" id="tagLimit">
                    限{price.get('restrictionList').map(restrict => restrict.get('label')).join('、')}预订
                </div>
                {restrictionList.size === 1
                    ? GVLimitationsEnums.map(wrapper => getGVLimitationsTooltip(wrapper))
                    : <ReactToolTip key="tagLimit" parentId="tagLimit" toolTipContent={getMultiGVLimitationsTooltipContent(GVLimitationsEnums)} />}
            </React.Fragment>
        ) : null,
        isVirtualFlight = flights.get('flightSegments').some(segment => segment.get('containsVirtualFlight')),
        // 显示经济舱售罄的水印，条件：1）搜索完成（多程超低价肯定是搜索完成） 2）最后一程或者往返组合（多程超低价就是显示所有程航班）  3）查询低舱 4）不包含虚拟航班 5）所有运价的所有舱位都是高舱
        showEconomicalCabinWaterMark = !isVirtualFlight
            && !prevCond.get('cabinEnum').value.isHighCabin
            && price.get('cabinEnumList').every(cabinEnum => cabinEnum.value.isHighCabin)

    const showPrice = () => {
        return (
            <div className="recmd-price">
                <div className={classnames({ price: true, abbr: isMultiplePassengerType })} id='multiCheapesPrice'>
                    {isMultiplePassengerType ? <ReactToolTip parentId='multiCheapesPrice' toolTipContent={flightWayEnum.getPriceTooltip(prevCond.get('adultCount'), prevCond.get('childCount'), prevCond.get('infantCount'), price, includeTax)} /> : null}
                    <dfn>¥</dfn>{avgPrice}
                    {/* <span className="qi">起</span> */}
                </div>
                {includeTax ? <div className="hint">{FLIGHT_WAY.MT.value.getPriceText(isMultiplePassengerType, true)}</div> : <div className="hint tax">{FLIGHT_WAY.MT.value.getTaxText(isMultiplePassengerType)}<dfn>&yen;</dfn>{avgTax}</div>}
            </div>
        )
    }

    return (
        <div className={classnames('flt-multi-recommend', { 'no-economic-cabin': showEconomicalCabinWaterMark })}>
            <div className="side-title">多程超低价</div>
            <FlightSummary globalSwitch={globalSwitch} passengerRestrictionTag={passengerRestrictionTag} flights={flights}
                isRecommend={true} isShowSegmentNo={true} penaltyTag={penaltyTag} prevCond={prevCond} comfortTags={comfortTags}
                changeComfortPopupIndex={changeComfortPopupIndex} comfortPopupIndex={comfortPopupIndex}
                onPopupFlightDetail={onPopupFlightDetail} searchCriteriaToken={searchCriteriaToken} />

            <div className="multi-book-box">
                <div className="for-middle">
                    {showPrice()}
                    <div className="recmd-action">
                        <div u_key='book_multiple_recommend' u_remark={`预订多程超低价航班[routeSearchToken:${price.get('routeSearchToken')}]`}
                            className={classnames({ 'btn': true, 'btn-recmd': true, 'less-ticket': ticketCount })}
                            onClick={onBook.bind(null, flights, price, { isFlightTrain: false, isSuperFlyer, needSendUbt: false, isFCRecommendYS: false })}>
                            预订
                            {ticketCount ? <i className="tag tag-less">余{ticketCount}张</i> : null}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MultiCheapest
