import React from 'react'
import { connect } from 'react-redux'
import FilterNoResultRecommend from '../../../../components/list/result/recommend/FilterNoResultRecommend'
import { genClickClearAll, genShowFilterNoResultWrapper } from '../../../../actions/list/filter'
import {
    prevCondSelector, currentSegmentSeqSelector, isBuildUpSelector
} from '../flight/baseSelector'

import { showFilterNoResultWrapperSelector } from './filterNoResultRecommendSelector'
import { NO_RESULT_RELEASE_COND_RECOMMEND } from '../../../../constants/list/enums/recommend'
import { LIST_UBT_KEY_TYPES, LIST_UBT_GROUP_TYPES } from '../../../../ubt.v2/list';
import converter from '../../../../sources/list/converter';

class FilterNoResultRecommendContainer extends React.Component {
    constructor(props) {
        super(props)
        this.noResultReason = this.noResultReason.bind(this)
    }

    componentDidMount() {
        let { prevCond, isBuildUp, onRemoveAll, filterNoResultReason, flights, currentSegment, genShowFilterNoResultWrapper } = this.props,
            visibleFlightsCount = flights.count()
        if (filterNoResultReason) {
            onRemoveAll()
            genShowFilterNoResultWrapper(filterNoResultReason)
        }
        // 埋点
        if (filterNoResultReason) {
            let flightSegmentsLength = prevCond.get('flightSegments').size,
                ubtInfo = LIST_UBT_GROUP_TYPES.BASIC_DATA_FLOW.value.rawData.NoResultRecommend ? LIST_UBT_GROUP_TYPES.BASIC_DATA_FLOW.value.rawData.NoResultRecommend : {}
            if (isBuildUp) {
                ubtInfo[0] = {
                    type: filterNoResultReason.ubtType,
                    num: visibleFlightsCount
                }
                ubtInfo[1] = {
                    type: filterNoResultReason.ubtType,
                    num: visibleFlightsCount
                }
            }
            else {
                ubtInfo[currentSegment] = {
                    type: filterNoResultReason.ubtType,
                    num: visibleFlightsCount
                }
            }
            for (let i = 0; i < flightSegmentsLength; i++) {
                if (!ubtInfo[i]) {
                    ubtInfo[i] = {
                        type: [],
                        num: null
                    }
                }
            }
            LIST_UBT_KEY_TYPES.RECOMMEND_NO_RESULT.value.invoke(ubtInfo)
            let ubtProps = {},
                ubtSourceInfoOfListPage = LIST_UBT_GROUP_TYPES.BASIC_DATA_FLOW.value.rawData.RecommendProduct ? LIST_UBT_GROUP_TYPES.BASIC_DATA_FLOW.value.rawData.RecommendProduct.SourceInfo : null
            ubtProps.NoResultRecommend = ubtInfo
            if (ubtSourceInfoOfListPage && Object.keys(ubtSourceInfoOfListPage).length > 0) {
                ubtProps.SourceInfo = converter.ubtSourceInfoCombiner(ubtSourceInfoOfListPage)
            }
            converter.saveRecommendSourceInfoForBookingPage(ubtProps, prevCond.get("transactionID"))
        }
    }

    /**
     * 查询无结果&放开查询条件有推荐
     * 机场： 搜索了机场但是筛选项里面没有该机场
     * 直飞：频道首页搜索了直飞但是列表页里面没有直飞航班
     * 航司：频道首页搜索了航司但是列表页里面没有该航司的信息
     * 多条件：上述三个条件满足其中两个或者以上
     */
    noResultReason() {
        let { prevCond, currentSegment, filterNoResultReason, isBuildUp } = this.props

        let departureCityName = isBuildUp ? prevCond.getIn(['flightSegments', 0, 'departureCityName']) :
            prevCond.getIn(['flightSegments', currentSegment, 'departureCityName']),
            arrivalCityName = isBuildUp ? prevCond.getIn(['flightSegments', 0, 'arrivalCityName']) :
                prevCond.getIn(['flightSegments', currentSegment, 'arrivalCityName']),
            reason = {}

        if (filterNoResultReason) {
            let type = filterNoResultReason.type,
                tag = NO_RESULT_RELEASE_COND_RECOMMEND[`${type}`].value,
                title = ''

            if (type == 'subAirport') {
                if (filterNoResultReason.departureAirport && filterNoResultReason.arrivalAirportCode) {
                    title = tag.title[2].replace(/DEPCITY/g, departureCityName)
                    title = title.replace(/AIRCITY/g, arrivalCityName)
                }
                else if (filterNoResultReason.departureAirport) {
                    title = tag.title[0].replace(/DEPCITY/g, departureCityName)
                }
                else {
                    title = tag.title[1].replace(/AIRCITY/g, arrivalCityName)
                }
                reason = {
                    text: tag.text,
                    title: title,
                    hint: tag.hint
                }
            }
            else if (type == 'airLine') {
                reason = {
                    text: tag.text.replace(/AIRLINE/g, filterNoResultReason.airlineName),
                    title: tag.title,
                    hint: tag.hint
                }
            }
            else if (type == 'isDirect') {
                reason = {
                    text: tag.text,
                    title: tag.title,
                    hint: tag.hint,
                    iconClass: 'ico-transfer'
                }
            }
            else {
                title = tag.title.replace(/DEPCITY/g, departureCityName)
                title = title.replace(/AIRCITY/g, arrivalCityName)

                reason = {
                    text: tag.text,
                    title: title,
                    hint: tag.hint
                }
            }
        }

        return reason
    }

    render() {
        let { filterNoResultReason } = this.props

        return (
            filterNoResultReason ? <FilterNoResultRecommend reason={this.noResultReason()} /> : null
        )
    }
}

const mapStateToProps = (state) => ({
    prevCond: prevCondSelector(state),
    currentSegment: currentSegmentSeqSelector(state),
    filterNoResultReason: showFilterNoResultWrapperSelector(state),
    isBuildUp: isBuildUpSelector(state),
    flights: state.getIn(['list', 'flights', 'list'])
})

const mapDispatchToProps = dispatch => ({
    onRemoveAll: () => {
        dispatch(genClickClearAll())
    },
    genShowFilterNoResultWrapper: (reason) => {
        dispatch(genShowFilterNoResultWrapper(reason))
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(FilterNoResultRecommendContainer)
