import { createSelector } from 'reselect'
import Immutable from 'immutable'
import { flightsSelector, prevCondSelector, isBuildUpSelector } from '../flight/baseSelector'
import { getFilterTypes } from '../../../../constants/list/enums/filter'
import { SUB_AIRPORT_RECOMMEND } from '../../../../constants/list/enums/recommend'
import { getFlatFilterGroupsSelector } from '../../sidebar/filterbar/filterbarSelector'
import { searchNoResultRecommendSelector } from './searchNoResultRecommendSelector'
import { getFilterNoResultReasonSelector } from './filterNoResultRecommendSelector'

const showSubAirportSelector = createSelector(getFlatFilterGroupsSelector, prevCondSelector, searchNoResultRecommendSelector, getFilterNoResultReasonSelector, isBuildUpSelector,
    (flatFilterGroups, prevCond, searchNoResult, showFilterNoResult, isBuildUp) => {
        let filterTypes = getFilterTypes()
        let departureAirportCode = prevCond.getIn(['flightSegments', 0, 'departureAirportCode']) || '',
            arrivalAirportCode = prevCond.getIn(['flightSegments', 0, 'arrivalAirportCode']) || '',
            departAirport, arrivalAirport,
            flatFilterGroupOfGoAirport = flatFilterGroups.get(filterTypes.GO_AIRPORT),
            flatFilterGroupOfBackAirport = flatFilterGroups.get(filterTypes.BACK_AIRPORT),
            flatFilterGroupOfAirport = flatFilterGroups.get(filterTypes.AIRPORT)

        if (!isBuildUp) {
            departAirport = flatFilterGroupOfAirport && flatFilterGroupOfAirport.getIn(['DEPART', 'items']).size > 1
            arrivalAirport = flatFilterGroupOfAirport && flatFilterGroupOfAirport.getIn(['ARRIVE', 'items']).size > 1
        } else {
            departAirport = flatFilterGroupOfGoAirport && flatFilterGroupOfGoAirport.getIn(['GO_DEPART', 'items']).size > 1
                && flatFilterGroupOfBackAirport && flatFilterGroupOfBackAirport.getIn(['BACK_ARRIVE', 'items']).size > 1
            arrivalAirport = flatFilterGroupOfGoAirport && flatFilterGroupOfGoAirport.getIn(['GO_ARRIVE', 'items']).size > 1
                && flatFilterGroupOfBackAirport && flatFilterGroupOfBackAirport.getIn(['BACK_DEPART', 'items']).size > 1
        }

        if (prevCond.get('flightWay') !== 'M' && !searchNoResult && !showFilterNoResult) {
            if (departureAirportCode && departAirport) return true
            if (arrivalAirportCode && arrivalAirport) return true
        }
        return false
    }
)

const subAirportSelector = createSelector(flightsSelector, prevCondSelector, showSubAirportSelector, isBuildUpSelector,
    (flights, prevCond, showSubAirport, isBuildUp) => {
        if (showSubAirport) {
            let departureAirportCode = prevCond.getIn(['flightSegments', 0, 'departureAirportCode']) || '',
                arrivalAirportCode = prevCond.getIn(['flightSegments', 0, 'arrivalAirportCode']) || '',
                departureCityName = prevCond.getIn(['flightSegments', 0, 'departureCityName']) || '',
                arrivalCityName = prevCond.getIn(['flightSegments', 0, 'arrivalCityName']) || '',
                directFlightsCount = 0,
                title, type

            // 设置子机场推荐的文本、类型
            if (isBuildUp) {
                title = SUB_AIRPORT_RECOMMEND.AIRPORT_BOTH.value.text.replace(/DCITY/g, departureCityName).replace(/ACITY/g, arrivalCityName)
                type = SUB_AIRPORT_RECOMMEND.AIRPORT_BOTH.value.type
            }
            else if (departureAirportCode && !arrivalAirportCode) {
                title = SUB_AIRPORT_RECOMMEND.AIRPORT_DEPART.value.text.replace(/DCITY/g, departureCityName)
                type = SUB_AIRPORT_RECOMMEND.AIRPORT_DEPART.value.type
            }
            else if (!departureAirportCode && arrivalAirportCode) {
                title = SUB_AIRPORT_RECOMMEND.AIRPORT_ARRIVE.value.text.replace(/ACITY/g, arrivalCityName)
                type = SUB_AIRPORT_RECOMMEND.AIRPORT_ARRIVE.value.type
            }
            else if (departureAirportCode && departureAirportCode) {
                title = SUB_AIRPORT_RECOMMEND.AIRPORT_BOTH.value.text.replace(/DCITY/g, departureCityName).replace(/ACITY/g, arrivalCityName)
                type = SUB_AIRPORT_RECOMMEND.AIRPORT_BOTH.value.type
            }

            // 获取全部航班和直飞航班的数量
            flights.get('list').map((flight) => {
                flight.getIn(['flightSegments', 0, 'transferAndStopCount']) === 0 ? directFlightsCount++ : undefined
            })

            return Immutable.fromJS({
                flightsCount: flights.get('list').size,
                directFlightsCount,
                title,
                type
            })
        }
    }
)

export {
    showSubAirportSelector,
    subAirportSelector
}
