import React from "react"

/**
 * 两舱无直飞补充直飞与中转
 * 两舱分组标题组件
 * @iconClassName: 多个机场推荐ico-subairport, 经济舱/超级经济舱直飞推荐ico-directflt,
 * 公务/头等舱 中转航班ico-transfer, 超级经济舱航班ico-seatType
 * @title: 上海所有机场出发/上海到达/上海到伦敦的/更多航司航班 @tip: 多个机场，选择更多文案
 * 经济舱/超级经济舱直飞航班 @tip: 更多时间和航司选择
 * 公务/头等舱 中转航班 @tip: 直飞无结果，看看中转吧
 * 超级经济舱航班 @tip: 更多时间和航司选择
 */
const GroupTitle = ({ ...props }) => {
    const { title, iconClassName, tip, onClick } = props
    return (
        <div className="otherline-title">
            <i className={iconClassName || "ico-subairport"} />
            <div className="title">
                {title}
                {tip ? <span className="hint"> <a onClick={onClick}></a> {tip} </span> : null}
            </div>
        </div>
    )
}

export default GroupTitle
