import React from 'react'
import { connect } from 'react-redux'
import Immutable from 'immutable'
import SubAirportRecommend from '../../../../components/list/result/recommend/subAirportRecommend'
import { genClickClearOfType } from '../../../../actions/list/filter'
import { genModifySearchCity } from '../../../../actions/list/search'
import { getFilterTypes } from '../../../../constants/list/enums/filter'
import { prevCondSelector, currentSegmentSeqSelector, containsTaxSelector, filterActiveKeysSelector, isBuildUpSelector, getMinPriceSelector } from '../flight/baseSelector'
import { showSubAirportSelector, subAirportSelector } from './subAirportRecommendSelector'
import { selectedCity } from '../../selector/search'

class SubAirportRecommendContainer extends React.Component {
    constructor(props) {
        super(props)
        this.state = { hide: false }
        this.showAllFlights = this.showAllFlights.bind(this)
    }

    componentWillReceiveProps(nextProp, _nextState) {
        // 筛选子机场推荐卡片关闭，其他筛选不关闭
        if (!this.props.isBuildUp) {
            if (this.props.filterActiveKeys.get('AIRPORT') !== nextProp.filterActiveKeys.get('AIRPORT') && !this.state.hide) {
                this.setState({ hide: true })
            }
        } else {
            let goAirport = this.props.filterActiveKeys.get('GO_AIRPORT') !== nextProp.filterActiveKeys.get('GO_AIRPORT'),
                backAirport = this.props.filterActiveKeys.get('BACK_AIRPORT') !== nextProp.filterActiveKeys.get('BACK_AIRPORT')
            if (goAirport || backAirport && !this.state.hide) {
                this.setState({ hide: true })
            }
        }

    }

    // 点击查看显示所有航班
    showAllFlights() {
        const filterTypes = getFilterTypes()
        if (this.props.isBuildUp) {
            this.props.clearType(filterTypes.GO_AIRPORT)
            this.props.clearType(filterTypes.BACK_AIRPORT)
        }
        else {
            this.props.clearType(filterTypes.AIRPORT)
            this.props.clearType(filterTypes.AIRLINE)
        }
        //获取城市
        let getSearchCityText = (seg, field) => {
            return {
                text: `${seg.get(`${field}CityName`)}(${seg.get(`${field}CityCode`)})`,
                Code: `${seg.get(`${field}CityCode`)}`,
                Name: `${seg.get(`${field}CityName`)}`
            }
        }
        let segment = this.props.prevCond.getIn(['flightSegments', 0]),
            param = {
                owDCity: getSearchCityText(segment, 'departure'),
                owACity: getSearchCityText(segment, 'arrival'),
                rtDCity: getSearchCityText(segment, 'departure'),
                rtACity: getSearchCityText(segment, 'arrival'),
                mtDCity1: getSearchCityText(segment, 'departure'),
                mtACity1: getSearchCityText(segment, 'arrival')
            }
        this.props.modifySearchCity(Immutable.fromJS(param))
    }
    render() {
        let { showSubAirport, subAirport, minPrice, prevCond, includeTax, currentSegmentSeq, isBuildUp, ubtOriginFlight } = this.props
        return showSubAirport && !this.state.hide && (currentSegmentSeq === 0 || isBuildUp)
            ? <SubAirportRecommend ubtOriginFlight={ubtOriginFlight} subAirport={subAirport} minPrice={minPrice} prevCond={prevCond} includeTax={includeTax} showAllFlights={this.showAllFlights} />
            : null
    }
}

const mapStateToProps = (state) => ({
    showSubAirport: showSubAirportSelector(state),
    subAirport: subAirportSelector(state),
    filterActiveKeys: filterActiveKeysSelector(state),
    minPrice: getMinPriceSelector(state),
    prevCond: prevCondSelector(state),
    includeTax: containsTaxSelector(state),
    selectedCity: selectedCity(state),
    currentSegmentSeq: currentSegmentSeqSelector(state),
    isBuildUp: isBuildUpSelector(state)
})

const mapDispatchToProps = dispatch => ({
    clearType: (param) => {
        dispatch(genClickClearOfType(param))
    },
    modifySearchCity: (param) => {
        dispatch(genModifySearchCity(param))
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(SubAirportRecommendContainer)